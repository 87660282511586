/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo, useContext } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

import { FeatureFlags } from "context/FeatureFlags";

// Material Dashboard 2 PRO React routes
import routes from "routes";

// Material Dashboard 2 PRO React contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
  setSidenavColor,
} from "context";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    hideSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const { features } = useContext(FeatureFlags);
  const isInitiallyLogged =
    localStorage.getItem("AuthorizationToken") !== null &&
    localStorage.getItem("AuthorizationToken") !== "undefined";

  theme.palette.background.default = features.colorBackground;
  theme.palette.primary.main = features.colorPrimary;
  theme.palette.primary.focus = features.colorPrimaryFocus;
  theme.palette.gradients.primary.main = features.colorPrimaryGradient;
  theme.palette.gradients.primary.state = features.colorPrimaryGradientState;

  /*
  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);
*/
  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {isInitiallyLogged &&
        pathname != "/sign-in" &&
        pathname != "/sign-up" &&
        pathname != "/done" &&
        pathname != "/success" &&
        pathname != "/broadcasts/preview" &&
        pathname != "/subscription" &&
        pathname != "/forgot" &&
        pathname != "/forgotDone" &&
        pathname != "/thank-you" &&
        pathname != "/confirm-email" &&
        pathname != "/passwordreset" &&
        pathname != "/already-subscribed" &&
        pathname != "/" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={`https://thumbs.${process.env.REACT_APP_MAIN_DOMAIN}/images/pixapageL.png`}
              brandMin={`https://thumbs.${process.env.REACT_APP_MAIN_DOMAIN}/templates/images/pixapage-logo-min.png`}
              brandName=""
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
          </>
        )}
      <Routes>
        {getRoutes(routes)}
        {isInitiallyLogged ? (
          <Route path="*" element={<Navigate to="/dashboard" />} />
        ) : (
          <Route path="/" element={<Navigate to="/" />} />
        )}
      </Routes>
    </ThemeProvider>
  );
}
